<template>
    <Toast />
    <Toolbar>
        <template #start>
            <h3>Domain Management</h3>
        </template>
    </Toolbar>
    <br />
    <div class="card">
        <div class="grid col-11">
            <div class="field col-5">
                <label>Admin Email-ID&nbsp;<span :style="{ color: 'red' }">*</span>&nbsp;</label>
                <InputText id="customer" placeholder="user.id@domain.com" v-model="domainName" type="text"
                    style="width:70%" />
            </div>
            <div class="field col-4">
                <label>Customer Name&nbsp;<span :style="{ color: 'red' }">*</span>&nbsp;</label>
                <InputText id="customerDomain" placeholder="Domain" v-model="customerName" type="text" />
            </div>
            <div class="col-2">
                <Button label="Submit" :disabled="!(customerName && domainName)" @click="SaveDomain" icon="pi pi-save"
                    class="p-button-success" />
            </div>
        </div>
    </div>
    <div v-if="loading_value">
        <i class="pi pi-spin pi-spinner" style="font-size: 1.3em; float: left;"></i>
        <p class="Loading"><i>&nbsp;{{ loading_value }}</i></p>
    </div>
</template>
    
<script>
import AdminService from '../tipureServices/AdminService';

export default {
    data() {
        return {
            loading_value: false,
            CustomerAccount: {},
            customerName: '',
            domainName: '',
            Cname: '',
            Dname: '',
            domainlist: [],
            accountlist: [],
        };
    },
    async created() {
        this.loading_value = "Fetching existing models, please wait...";
        this.adminService = new AdminService();
        await this.adminService.getCustomerDomainList().then((data) => {
            this.CustomerAccount = data.data;
            this.loading_value = null;
        }).catch(err => {
            this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
            this.loading_value = null;
        });
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1).toLowerCase();
        },
        extractnames() {
            for (let count in this.CustomerAccount) {
                this.domainlist.push(this.CustomerAccount[count]['domain'].toLowerCase())
            }
            for (let count in this.CustomerAccount) {
                this.accountlist.push(this.CustomerAccount[count]['name'])
            }
        },
        SaveDomain() {
            let domainName = ""
            let customerName = ""
            this.loading_value = "Provisioning the domain, please wait...";
            this.extractnames();
            if ((this.domainName.match(/@/g) != null) && ((this.domainName.match(/@/g)).length === 1)) {
                if ((this.domainName.split("@")).length == 2) {
                    domainName = this.domainName.split("@")[1].replace(".", "")
                    customerName = this.capitalizeFirstLetter(this.customerName)
                    if (!(this.domainlist.includes(domainName.toLowerCase()) ||
                        (this.accountlist.includes(customerName)))) {
                        this.Dname = this.domainName;
                        this.Cname = this.customerName;
                        this.adminService.AddnewDomain(this.Dname).then((data) => {
                            if (data.code === 201) {
                                this.$toast.add({ severity: 'success', summary: "created resources Successfully", detail: data.data, life: 1000 });
                                this.loading_value = "Uploading new files.This may take a while, please wait..."
                                setTimeout(() => {
                                    this.adminService.PushcombinedPortfolio(this.Dname, this.Cname).then((data) => {
                                        this.$toast.add({ severity: 'success', summary: "Domain added Successfully", detail: data.data, life: 2000 });
                                        this.customerName = "";
                                        this.domainName = "";
                                        this.Dname = "";
                                        this.Cname = "";
                                        this.loading_value = false;
                                        setTimeout(() => {
                                            this.$router.push({ path: '/admin/newModel' });
                                        }, 500);
                                    }).catch(err => {
                                        this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
                                        this.loading_value = false;
                                        this.Dname = "";
                                        this.Cname = "";
                                    });
                                }, 1000);
                            }
                            else {
                                alert("Exception Occured while creating Storage")
                                this.Dname = "";
                                this.Cname = "";
                                this.loading_value = false;
                                this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: data.data, life: 5000 });
                            }
                        }).catch(err => {
                            this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
                            this.loading_value = false;
                        });

                    }
                    else {
                        alert("Domain already exists,please add new domain!")
                        this.customerName = "";
                        this.domainName = "";
                        this.loading_value = false;
                    }
                }
                else {
                    alert("Enter a valid e-mail address")
                    this.loading_value = false;
                }
            }
            else {
                alert("Enter a valid e-mail address")
                this.loading_value = false;
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.Loading {
    font-size: small;
    color: rgb(163, 159, 159);
    margin: 1px;
}
</style>